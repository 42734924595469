var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "homeProcess" }, [
    _c("div", { staticClass: "navigation" }, [
      _c(
        "div",
        { staticStyle: { margin: "150px 20px" } },
        [
          _c("Row", [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "u-select" }, [
                  _c("img", {
                    staticClass: "rect",
                    attrs: { src: "/big_screen/images/tjdw.png" },
                    on: {
                      click: function ($event) {
                        return _vm.goto(1)
                      },
                    },
                  }),
                  _c("p", [_vm._v("单位信息")]),
                ]),
              ]),
              _c("div", { staticClass: "arrow" }, [
                _c("div", { staticClass: "arrow-body" }),
                _c("div", { staticClass: "arrow-footer" }),
              ]),
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "u-select" }, [
                  _c("img", {
                    staticClass: "rect",
                    attrs: { src: "/big_screen/images/bm.png" },
                    on: {
                      click: function ($event) {
                        return _vm.goto(2)
                      },
                    },
                  }),
                  _c("p", [_vm._v("体检登记")]),
                ]),
              ]),
              _c("div", { staticClass: "arrow" }, [
                _c("div", { staticClass: "arrow-body" }),
                _c("div", { staticClass: "arrow-footer" }),
              ]),
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "u-select" }, [
                  _c("img", {
                    staticClass: "rect",
                    attrs: { src: "/big_screen/images/cj.png" },
                    on: {
                      click: function ($event) {
                        return _vm.goto(3)
                      },
                    },
                  }),
                  _c("p", [_vm._v("体检诊台")]),
                ]),
              ]),
              _c("div", { staticClass: "arrow" }, [
                _c("div", { staticClass: "arrow-body" }),
                _c("div", { staticClass: "arrow-footer" }),
              ]),
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "u-select" }, [
                  _c("img", {
                    staticClass: "rect",
                    attrs: { src: "/big_screen/images/zj.png" },
                    on: {
                      click: function ($event) {
                        return _vm.goto(4)
                      },
                    },
                  }),
                  _c("p", [_vm._v("医生总检")]),
                ]),
              ]),
              _c("div", { staticClass: "arrow" }, [
                _c("div", { staticClass: "arrow-body" }),
                _c("div", { staticClass: "arrow-footer" }),
              ]),
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "u-select" }, [
                  _c("img", {
                    staticClass: "rect",
                    attrs: { src: "/big_screen/images/dybg.png" },
                    on: {
                      click: function ($event) {
                        return _vm.goto(5)
                      },
                    },
                  }),
                  _c("p", [_vm._v("体检报告")]),
                ]),
              ]),
              _c("div", { staticClass: "arrow" }, [
                _c("div", { staticClass: "arrow-body" }),
                _c("div", { staticClass: "arrow-footer" }),
              ]),
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "u-select" }, [
                  _c("img", {
                    staticClass: "rect",
                    attrs: { src: "/big_screen/images/健康证.png" },
                    on: {
                      click: function ($event) {
                        return _vm.goto(6)
                      },
                    },
                  }),
                  _c("p", [_vm._v("健康证")]),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }